import { AuthProvider } from '@redwoodjs/auth'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { Auth0Client } from '@auth0/auth0-spa-js'

import EnvironmentAwareErrorBoundary from 'src/components/EnvironmentAwareErrorBoundary/EnvironmentAwareErrorBoundary'
import Routes from 'src/Routes'

import './index.css'

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  client_id: process.env.AUTH0_CLIENT_ID,
  redirect_uri: process.env.AUTH0_REDIRECT_URI,
  cacheLocation: 'localstorage',
  audience: process.env.AUTH0_AUDIENCE,
})

const App = () => (
  <EnvironmentAwareErrorBoundary>
    <AuthProvider client={auth0} type="auth0">
      <RedwoodApolloProvider>
        <Routes />
      </RedwoodApolloProvider>
    </AuthProvider>
  </EnvironmentAwareErrorBoundary>
)

export default App
