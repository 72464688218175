// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Private, Router, Route } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      {/* WEBSITE Routes */}
      <Route path="/" page={HomePage} name="home" />
      <Route path="/login/" page={LoginPage} name="login" />
      <Route
        path="/login/callback/"
        page={LoginCallbackPage}
        name="loginCallback"
      />
      <Route path="/terms/" page={TermsPage} name="terms" />
      <Route path="/privacy/" page={PrivacyPage} name="privacy" />
      <Route path="/contact/" page={ContactPage} name="contact" />
      <Route path="/about/" page={AboutPage} name="about" />
      <Route
        path="/consultation/"
        page={ConsultationPage}
        name="consultation"
      />
      <Route path="/dashboard/" page={DashboardPage} name="dashboard" />
      <Route
        path="/on-immigration-restrictions/"
        page={PassportRestrictionsPage}
        name="passportRestrictions"
      />
      <Route
        path="/destinations/"
        page={DestinationsPage}
        name="destinations"
      />
      <Route
        path="/destinations/{slug}/"
        page={DestinationPage}
        name="destination"
      />
      <Route
        path="/destinations/{destinationSlug}/{pathwaySlug}/"
        page={PathwayPage}
        name="pathway"
      />
      <Route path="/jobs/" page={JobsPage} name="jobs" />
      <Route
        path="/jobs/{countrySlug}/{companySlug}/{jobSlug}/"
        page={JobPage}
        name="job"
      />
      <Private unauthenticated="login">
        {/* REGISTERED MEMBER Routes */}
        <Route
          path="/consultation/application/"
          page={ApplicationAddPage}
          name="consultationApplication"
        />
      </Private>
      <Private unauthenticated="login" role="admin">
        {/* ADMIN Routes */}
        <Route path="/admin/14021986/" page={AdminHomePage} name="adminHome" />
        <Route
          path="/admin/14021986/destinations/add/"
          page={AdminDestinationAddPage}
          name="adminDestinationAdd"
        />
        <Route
          path="/admin/14021986/destinations/{id:Int}/edit/"
          page={AdminDestinationEditPage}
          name="adminDestinationEdit"
        />
        <Route
          path="/admin/14021986/destinations/{id:Int}/"
          page={AdminDestinationPage}
          name="adminDestination"
        />
        <Route
          path="/admin/14021986/destinations/"
          page={AdminDestinationsPage}
          name="adminDestinations"
        />

        <Route
          path="/admin/14021986/pathways/add/"
          page={AdminPathwayAddPage}
          name="adminPathwayAdd"
        />
        <Route
          path="/admin/14021986/pathways/{id:Int}/edit/"
          page={AdminPathwayEditPage}
          name="adminPathwayEdit"
        />
        <Route
          path="/admin/14021986/pathways/{id:Int}/"
          page={AdminPathwayPage}
          name="adminPathway"
        />
        <Route
          path="/admin/14021986/pathways/"
          page={AdminPathwaysPage}
          name="adminPathways"
        />

        <Route
          path="/admin/14021986/visas/add/"
          page={AdminVisaAddPage}
          name="adminVisaAdd"
        />
        <Route
          path="/admin/14021986/visas/{id:Int}/edit/"
          page={AdminVisaEditPage}
          name="adminVisaEdit"
        />
        <Route
          path="/admin/14021986/visas/{id:Int}/"
          page={AdminVisaPage}
          name="adminVisa"
        />
        <Route
          path="/admin/14021986/visas/"
          page={AdminVisasPage}
          name="adminVisas"
        />

        <Route
          path="/admin/14021986/cities/add/"
          page={AdminCityAddPage}
          name="adminCityAdd"
        />
        <Route
          path="/admin/14021986/cities/{id:Int}/edit/"
          page={AdminCityEditPage}
          name="adminCityEdit"
        />
        <Route
          path="/admin/14021986/cities/{id:Int}/"
          page={AdminCityPage}
          name="adminCity"
        />
        <Route
          path="/admin/14021986/cities/"
          page={AdminCitiesPage}
          name="adminCities"
        />

        <Route
          path="/admin/14021986/companies/add/"
          page={AdminCompanyAddPage}
          name="adminCompanyAdd"
        />
        <Route
          path="/admin/14021986/companies/{id:Int}/edit/"
          page={AdminCompanyEditPage}
          name="adminCompanyEdit"
        />
        <Route
          path="/admin/14021986/companies/{id:Int}/"
          page={AdminCompanyPage}
          name="adminCompany"
        />
        <Route
          path="/admin/14021986/companies/"
          page={AdminCompaniesPage}
          name="adminCompanies"
        />

        <Route
          path="/admin/14021986/industries/add/"
          page={AdminIndustryAddPage}
          name="adminIndustryAdd"
        />
        <Route
          path="/admin/14021986/industries/{id:Int}/edit/"
          page={AdminIndustryEditPage}
          name="adminIndustryEdit"
        />
        <Route
          path="/admin/14021986/industries/{id:Int}/"
          page={AdminIndustryPage}
          name="adminIndustry"
        />
        <Route
          path="/admin/14021986/industries/"
          page={AdminIndustriesPage}
          name="adminIndustries"
        />

        <Route
          path="/admin/14021986/jobs/add/"
          page={AdminJobAddPage}
          name="adminJobAdd"
        />
        <Route
          path="/admin/14021986/jobs/{id:Int}/edit/"
          page={AdminJobEditPage}
          name="adminJobEdit"
        />
        <Route
          path="/admin/14021986/jobs/{id:Int}/"
          page={AdminJobPage}
          name="adminJob"
        />
        <Route
          path="/admin/14021986/jobs/"
          page={AdminJobsPage}
          name="adminJobs"
        />
      </Private>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
