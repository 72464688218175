import { FatalErrorBoundary } from '@redwoodjs/web'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage'

const EnvironmentAwareErrorBoundary = React.memo(
  ({ children, ...otherProps }) => {
    if (process.env.REDWOOD_ENV_NAME === 'local') {
      return (
        <FatalErrorBoundary page={FatalErrorBoundary} {...otherProps}>
          {children}
        </FatalErrorBoundary>
      )
    } else {
      Bugsnag.start({
        apiKey: process.env.BUGSNAG_FRONTEND_API_KEY,
        plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.REDWOOD_ENV_NAME,
        appVersion: process.env.DEPLOY_ID,
      })

      const BugsnagBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
        React
      )

      return (
        <BugsnagBoundary
          FallbackComponent={<FatalErrorBoundary page={FatalErrorPage} />}
          {...otherProps}
        >
          {children}
        </BugsnagBoundary>
      )
    }
  }
)

export default EnvironmentAwareErrorBoundary
